import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useLocation } from "@reach/router"
import { SwitchTransition, Transition } from "react-transition-group"

import Header from "./Header"

import "../styles/main.scss"

const TRANSITION_DURATION = 400

const TRANSITION_STYLES = {
  default: {
    transition: `opacity ${TRANSITION_DURATION}ms ease`,
    opacity: 0,
    paddingBottom: "50px",
  },
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
}

const Layout = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    const body = document.body

    const colorClasses = [
      "body--mustard",
      "body--terracotta",
      "body--sage",
      "body--grey",
      "body--blue",
      "body--taupe",
    ]

    switch (location.pathname) {
      case "/":
        body.classList.remove(...colorClasses)
        body.classList.add("body--mustard")
        break
      case "/plays":
        body.classList.remove(...colorClasses)
        body.classList.add("body--terracotta")
        break
      case "/directing":
        body.classList.remove(...colorClasses)
        body.classList.add("body--taupe")
        break
      case "/teaching":
        body.classList.remove(...colorClasses)
        body.classList.add("body--blue")
        break
      case "/press":
        body.classList.remove(...colorClasses)
        body.classList.add("body--sage")
        break
      case "/book":
        body.classList.remove(...colorClasses)
        body.classList.add("body--grey")
        break
      default:
        body.classList.remove(...colorClasses)
    }
  }, [location.pathname])

  return (
    <React.Fragment>
      <Header />
      <SwitchTransition>
        <Transition
          key={location.pathname}
          mountOnEnter
          unmountOnExit
          appear
          timeout={TRANSITION_DURATION}
        >
          {status => (
            <main
              id="maincontent"
              style={{
                ...TRANSITION_STYLES.default,
                ...TRANSITION_STYLES[status],
              }}
            >
              {children}
            </main>
          )}
        </Transition>
      </SwitchTransition>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
