import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = () => (
  <header className="site-header psy grid-container contained pt2 pt3--md pb2 pb3--md">
    <nav className="df jcb">
      <Link
        to="/"
        className="header--sm header--md--sm header--lg--lg uppercase"
      >
        Peter Coy
      </Link>
      <Link
        to="/"
        className="header--sm header--md--sm header--lg--lg uppercase"
      >
        Playwright
      </Link>
    </nav>
    <nav className="site-header__nav pt1">
      <Link
        to="/book"
        activeClassName="active"
        className="site-header__nav-item serif--sm serif--md--md serif--lg--lg mr1 mr3--md mr7--lg"
      >
        Book
      </Link>
      <Link
        to="/"
        activeClassName="active"
        className="site-header__nav-item serif--sm serif--md--md serif--lg--lg mr1 mr3--md mr7--lg"
      >
        Information
      </Link>
      <Link
        to="/plays"
        activeClassName="active"
        className="site-header__nav-item serif--sm serif--md--md serif--lg--lg mr1 mr3--md mr7--lg"
      >
        Plays
      </Link>
      <Link
        to="/directing"
        activeClassName="active"
        className="site-header__nav-item serif--sm serif--md--md serif--lg--lg mr1 mr3--md mr7--lg"
      >
        Directing
      </Link>
      <Link
        to="/teaching"
        activeClassName="active"
        className="site-header__nav-item serif--sm serif--md--md serif--lg--lg mr1 mr3--md mr7--lg"
      >
        High School Project
      </Link>
      <Link
        to="/press"
        activeClassName="active"
        className="site-header__nav-item serif--sm serif--md--md serif--lg--lg"
      >
        Press
      </Link>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  className: "",
}

export default Header
